import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { InputField } from "../../../components/DesignSystem/FormField";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import ModalBox from "../../../components/DesignSystem/ModalBox";
import { addBanner, stripePayment } from "../../../action";
import { BannerShema } from "../../../validationShema/BannerShema";
import { Banner } from "../../../components/DesignSystem/Image/Banner";

const resData = {
	info: {
		banner_title: "",
		image: "",
	},
};
const AddBanner = forwardRef(({}, ref) => {
	const [isEdit, setIsEdit] = useState(false);

	const dispatch = useDispatch();
	const openModal = useRef();
	const imageRef = useRef();

	const formOptions = {
		resolver: yupResolver(BannerShema),
	};
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			...resData,
		},
	});

	useImperativeHandle(ref, (item) => ({
		handleAddBanner(item) {
			if (item) {
				setIsEdit(true);
				setValue("info", item);
				imageRef.current.resetImage(item?.image);
			} else {
				reset(resData);
				setIsEdit(false);
				imageRef.current.resetImage("");
			}
			openModal.current.handleModalBox();
		},
	}));

	const onSubmit = (obj, e) => {
		obj.info.image = e.target.image.files[0];
		const data = obj.info;

		dispatch(addBanner(data));
		reset({ ...resData });
	};
	return (
		<ModalBox
			ref={openModal}
			size="lg"
			title={`${isEdit ? "Edit" : "Add"} Banner`}
		>
			<div className="container p-2">
				<div className="p-2">
					<form className="box" onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-md-12">
								<Controller
									name="info.banner_title"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Banner title"
											errorMessage="Banner title"
											type="text"
											onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>

							<div className="col-md-12 ">
								<Banner errors={errors} control={control} ref={imageRef} />
							</div>
						</div>

						<div className="d-flex justify-content-end mt-4">
							<OutlineButton
								label={`${isEdit ? "Upate" : "Create"} Banner`}
								//onClick={addBanner}
							/>
						</div>
					</form>
				</div>
			</div>
		</ModalBox>
	);
});
export default AddBanner;
