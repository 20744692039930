export const endpoint = {
	userInfo: "loginAdmin",
	adminUser: "admin-user",
	getUsersDetails: "users-details",
	user: "admin-user",
	category: "ozFoodCategory",
	trashCategory: "ozFoodTrash",
	restoreCategory: "restore-category",
	banner: "ozFoodBanner",
	payment: "ozFoodPayment",
	notification: "ozFoodNotification",
	product: "ozFoodProduct",
	inventoryLowStock: "ozFoodInventory",
	inventorySellingProducts: "oz-food-odering-selling-products",
	lowOutOfStockProducts: "oz-food-odering-low-and-out-of-stock-products-cnt",
	orderInvoicePDF: "oz-food-odering-order-invoice-pdf",
	stock: "ozFoodInventory",
	orderDetails: "ozFoodOrder",
	getOrderReports: "oz-food-odering-order-reports",
	deleteProduct: "oz-food-odering-product-remove",
	updateProductStatus: "oz-food-odering-product-status-update",
	updateOrderAddress: "oz-food-odering-change-order-address",
	deleteBanner: "oz-food-odering-banner-remove",
	updateBannerStatus: "oz-food-odering-banner-status-update",
	deleteNotification: "oz-food-odering-notification-remove",
	updateNotificationStatus: "oz-food-odering-notification-status-update",
	deleteCategory: "oz-food-odering-category-remove",
	updateCategoryStatus: "oz-food-odering-category-status-update",
	deleteUser: "users-details-remove",
	updateUserStatus: "users-details-status-update",
	fetchUserType: "user-type-count-date-wise",
	getVarientImages: "oz-food-odering-get-varient-images",
	deleteVarientImage: "oz-food-odering-varient-image-remove",
	editVarientImage: "oz-food-odering-varient-image-edit",
	editOrderStatus: "oz-food-odering-change-order-status",
	productCategoryList: "oz-food-odering-product-category-list",
	getSubCategory: "oz-food-odering-sub-category",
	sendNotification: "oz-food-odering-send-notification",
	stripePayment: "stripe-payment",
	deleteRequest: "remove-user",
};
