import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { Container, LogoContainer } from "./styled";
import { InputField } from "../../components/DesignSystem/FormField";
import { LoginShema } from "../../validationShema/LoginShema";
import { adminLogin, deleteRequest } from "../../action/userAction";
import { EMAIL_MOBILE_REGEX } from "../../Constant";
import {
	ViewCloseIcon,
	ViewIcon,
} from "../../components/DesignSystem/Icons/Icons";
export const RequestDelete = () => {
	const logoImage = require(`../../images/logo.png`);
	const [isShow, setisShow] = useState(false);
	const dispatch = useDispatch();

	const formOptions = {
		//resolver: yupResolver(LoginShema),
	};

	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
	} = useForm({
		defaultValues: {
			email: "",
			mobile_no: "",
			name: "",
		},
	});

	const onSubmit = (obj) => {
		dispatch(deleteRequest(obj));
		reset();
	};
	return (
		<>
			<Container>
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-2"></div>
						<div className="col-lg-5 col-md-8 login-box">
							<div className="col-lg-12 login-key">
								<i className="fa fa-key" aria-hidden="true"></i>
							</div>
							<div className="col-lg-12 mt-3 company-title">
								<LogoContainer>
									{" "}
									<img
										src={logoImage}
										alt="..."
										className="animated bounce"
										style={{ width: "4rem" }}
									/>
								</LogoContainer>
								&nbsp; DELETE REQUEST FORM
							</div>
							{/* <div className="col-lg-12 login-title">ADMIN LOGIN</div> */}

							<div className="col-lg-12 ">
								<div className="col-lg-12  p-3">
									<form onSubmit={handleSubmit(onSubmit)}>
										<div className=" mb-4">
											<Controller
												name="name"
												control={control}
												rules={{ required: true }}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Name"
														errorMessage="Name"
														type="text"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
										<div className="password text-end mb-4">
											<Controller
												name="mobile_no"
												control={control}
												rules={{ required: true }}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														type="number"
														name={name}
														value={value}
														errors={errors}
														placeholder="Enter mobile no"
														errorMessage="Enter mobile no"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
										<div className=" mb-4">
											<Controller
												name="email"
												control={control}
												rules={{ required: true, pattern: EMAIL_MOBILE_REGEX }}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Email"
														errorMessage="Email"
														type="text"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>

										<div className="col-lg-12 loginbttm  mb-3 text-end">
											<button
												type="submit"
												className="px-3 py-2 btn btn-outline-primary "
											>
												Submit
											</button>
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-3 col-md-2"></div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};
